import Vue from 'vue';
import App from './App.vue';

// Check if Vue.config is defined before accessing it
if (Vue.config) {
  Vue.config.productionTip = false;
}

new Vue({
  render: (h) => h(App),
}).$mount('#app');